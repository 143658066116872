import React, { Component } from 'react';
import '../styles/GraphPage.css';
import {Redirect} from 'react-router-dom'; 
import {connect} from 'react-redux';
import {Button} from 'primereact/button';
import {HOME} from '../../navigation/routes';
import GraphSelector from './GraphSelector';
import {fetchLatest, signOut} from '../../actions/dataActions';
import LoadingScreen from '../loading/LoadingScreen';

class GraphPage extends Component {
  componentDidMount() {
    if (this.props.patientCode) {
      this.props.fetchLatest(this.props.patientCode)
    }
  }

  handleSignOut = () => {
    this.props.signOut()
  }
 
  renderPatientInfo = () => (
    <div>
      <div className='p-grid'>
        <div className='p-col'>
          Patient
        </div>
        <div className='p-col'>
          {this.props.patientCode ? this.props.patientCode : '--'}  
        </div>
      </div>
      <div className='p-grid'>
        <div className='p-col'>
          Latest Temperature
        </div>
        <div className='p-col'>
          {this.props.latestTemp.reading ? this.props.latestTemp.reading : '--'} °F 
        </div>
      </div>
      <div className='p-grid'>
        <div className='p-col'>
          Latest SPO2
        </div>
        <div className='p-col'>
          {this.props.latestSpo2.reading ? this.props.latestSpo2.reading : '--'}% 
        </div>
      </div>
      <div className='p-grid'>
        <div className='p-col'>
          Latest Pulse Rate
        </div>
        <div className='p-col'>
          {this.props.latestPulse.reading ? this.props.latestPulse.reading : '--'} beats/min
        </div>
      </div>
    </div>
  )

  render() {
    if(!this.props.tempComplete | !this.props.pulseOxComplete) {
      return <Redirect to={{pathname: HOME}} />
    }
    return (
        <div className="graph-page-bg">
          <div className='p-grid p-justify-start'>
            <Button
              label="Start Over"
              className="p-button-primary"
              onClick={() => this.handleSignOut()}
              icon="pi pi-chevron-left"
              iconPos="left"
              style={{
                minWidth:'50px', 
                marginBottom: '25 px', 
                marginLeft: '10px', 
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: 'rgba(0,0,0,0)',
              }}
            />
          </div>
          <div className="p-grid p-align-end">
            <div className='p-col-12 p-sm-12 p-lg-6'>
              <div className="p-grid p-dir-col">
                <div className='p-col'>
                  <h1 className='covidi-header'>My Patchd Data</h1>
                </div>
                <div className='patient-section'>
                  { this.props.loading === true 
                  ? <LoadingScreen />
                  : this.renderPatientInfo()}
                </div>            
              </div>
            </div>
            <div className='p-col-12  p-sm-12 p-lg-6 graph-section'>
              <GraphSelector />
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  patientCode: state.data.patientCode,
  latestTemp: state.data.latestTemp,
  latestSpo2: state.data.latestSpo2,
  latestPulse: state.data.latestPulse,
  tempComplete: state.data.fetchStatusTemp,
  pulseOxComplete: state.data.fetchStatusPulseOx,
  loading: state.data.loading,
})

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(signOut()),
  fetchLatest: (id) => dispatch(fetchLatest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GraphPage);